import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../services/supabaseClient";

const Login: React.FC<{ onLoginSuccess: () => void }> = ({
  onLoginSuccess,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    const { error, data } = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    if (error) {
      alert("Login failed: " + error.message);
    } else {
      localStorage.setItem("access_token", data?.session.access_token);
      alert("Login successful!");
      console.log("redirect");

      // Immediately mark user as logged in
      onLoginSuccess();
      navigate("/generateQr");
    }
  };

  const handleGoToSignup = () => {
    navigate("/signup");
  };

  return (
    <div>
      <h2>Login</h2>
      <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <input
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type="password"
        placeholder="Password"
      />
      <button onClick={handleLogin}>Login</button>
      <button onClick={handleGoToSignup} style={{ marginTop: "10px" }}>
        Go to Signup
      </button>
    </div>
  );
};

export default Login;

import React, { useState } from "react";
import { supabase } from "../../services/supabaseClient";
import { useNavigate } from "react-router-dom";

const Signup: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleSignup = async () => {
    const { error } = await supabase.auth.signUp({ email, password });
    if (error) {
      alert("SignUp failed: " + error.message);
    } else {
      alert("SignUp successful!");
    }
  };

  const handleGoToLogin = () => {
    navigate("/login");
  };

  return (
    <div>
      <h2>SignUp</h2>
      <input
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <input
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type="password"
        placeholder="Password"
      />
      <button onClick={handleSignup}>SignUp</button>
      <button onClick={handleGoToLogin} style={{ marginTop: "10px" }}>
        Go to Login
      </button>
    </div>
  );
};

export default Signup;
